<template>
  <ion-page>
    <ion-header class="profile-header">
      <ion-toolbar class="profile-toolbar">
        <ion-segment
          mode="md"
          :value="currentTab"
          class="segment"
          @ionChange="segmentChanged($event)"
        >
          <ion-segment-button
            class="segment-button"
            value="following"
          >
            <ion-label class="label">{{ $t('following.following') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button
            class="segment-button"
            value="favorites"
          >
            <ion-label class="label">{{ $t('following.favorites') }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isLoading" >
      <div class="is-flex ion-align-items-center ion-justify-content-center h-100">
        <IonSpinner/>
      </div>
    </ion-content>
    <ion-content
      v-else
      class="ion-padding-start ion-padding-end pt-5"
    >
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh($event)"
      >
        <ion-refresher-content/>
      </ion-refresher>

      <div class="h-100 tablet-centered">
        <MainFollowing
          v-show="currentTab === 'following'"
          :query-id="queryId"
        />
        <FollowingFavorites v-show="currentTab === 'favorites'"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import MainFollowing from '@/components/following/MainFollowing.vue';
import FollowingFavorites from '@/components/following/FollowingFavorites.vue';

import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonRouterOutlet,
  IonSpinner,
  onIonViewWillEnter,
  onIonViewDidEnter, IonRefresher, IonRefresherContent,
} from '@ionic/vue';

import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from "vuex";
import useTracking from "@/composables/useTracking";
import {TRACKING_FAVORITES, TRACKING_FOLLOWING, TRACKING_LIBRARY, TRACKING_NEW_RELEASES} from "@/config/constants";

export default {
  name: 'Following',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    MainFollowing,
    FollowingFavorites,
    IonIcon,
    IonRouterOutlet,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();

    const currentTab = ref('following');
    const isLoading = ref(true);
    const queryId = ref(null);

    const trackTab = () => {
      currentTab.value === 'following' ? trackThis(TRACKING_FOLLOWING) : trackThis(TRACKING_FAVORITES);
    };

    const segmentChanged = (ev) => {
      currentTab.value = ev.detail.value;
      trackTab();
    };

    onIonViewWillEnter(() => {
      if (route.query.tab) {
        currentTab.value = route.query.tab;
        router.replace(route.path);
      }
      queryId.value = null;
    });
    onIonViewDidEnter(() => {
      if (isLoading) {
        setTimeout(() => {
          isLoading.value = false;
          trackTab();
          if (route.query.id) {
            queryId.value = route.query.id;
          }
        }, 0);
      }
    });

    const refresh = async (ev) => {
      if (currentTab.value === 'favorites') {
        return await store.dispatch('favorites/setFavoritesList', false)
          .finally(() => {
            ev.target.complete();
          });
      }
      if (currentTab.value === 'following') {
        return await store.dispatch('favorites/setFollowingList')
          .finally(() => {
            ev.target.complete();
          });
      }

      ev.target.complete();
    };

    return {
      segmentChanged,
      currentTab,
      refresh,
      isLoading,
      queryId,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-tab-button {
  --color: red;
}



.segment {
  --background: none;
}

ion-segment-button::part(indicator-background) {
  border-radius: 12px;
}

.label {
  font-size: 20px;
}
</style>

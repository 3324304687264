<template>
  <div class="favorites-component">
    <div
      v-if="loading"
      class="is-flex ion-align-items-center ion-justify-content-center pt-4"
    >
      <IonSpinner/>
    </div>

    <h3
      v-if="!loading && !favoritesList.length"
      class="ion-margin-top ion-text-center"
    > {{ $t('following.favoritePlaceholder') }} </h3>

    <company-podcasts
      v-for="(data, i) in favoritesList"
      :key="`data-${i}`"
      :company-details="data.company"
    >
      <SeriesPodcast
        v-for="(course, y) in data.courses"
        :key="`series-${y}`"
        :course-data="course"
        @click="$router.push(`/course/${course.id}`)"
      />
      <SinglePodcast
        v-for="(stream, index2) in data.streams"
        :key="`single-${index2}`"
        :podcast-data="stream"
        :course-media="getCourseMedia(stream)"
        @click="openPlayer(stream)"
      />
    </company-podcasts>
  </div>
</template>

<script>

import CompanyPodcasts from '@/components/common/CompanyPodcasts.vue';
import SeriesPodcast from '@/components/common/SeriesPodcast.vue';
import SinglePodcast from '@/components/common/SinglePodcast.vue';
import { computed, ref } from '@vue/reactivity';
import {useStore} from "vuex";
import { IonSpinner } from '@ionic/vue';
import {watch} from "@vue/runtime-core";

export default {
  name: 'FollowingFavorites',
  components: {
    CompanyPodcasts,
    SeriesPodcast,
    SinglePodcast,
    IonSpinner,
  },

  setup() {
    const store = useStore();

    const loading = ref(false);

    const favoritesList = computed(() => store.getters['favorites/getFavoritesList']);

    const fetchFavorites = () => {
      loading.value = true;
      store.dispatch('favorites/setFavoritesList')
        .finally(() => {
          loading.value = false;
        });
    };

    if (!store.state.favorites.dataFetched) {
      fetchFavorites();
    }

    watch(
      () => store.state.favorites.favoritesUpdated,
      (newValue) => {
        if (newValue) {
          fetchFavorites(newValue);
          store.commit('favorites/setFavoritesUpdated', false);
        }
      },
    );

    const openPlayer = (stream, streams) => {
      const course = getCourse(stream);
      const startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);

      store.dispatch('player/openPlayer', {
        company: course.company,
        course,
        playlist: course.streams,
        startAt,
      });
    };

    const getCourseMedia = (stream) => {
      const rawFavoritesList = store.state.favorites.rawFavoritesList ?? [];
      return rawFavoritesList.find(course => course.id === stream.course_id)?.media ?? [];
    };

    const getCourse = (stream) => {
      const rawFavoritesList = store.state.favorites.rawFavoritesList ?? [];
      return rawFavoritesList.find(course => course.id === stream.course_id) ?? {};
    };


    return {
      loading,
      openPlayer,
      favoritesList,
      getCourseMedia,
      fetchFavorites,
    };
  },
};
</script>

<style lang="scss" scoped>
  .sort-btn-wrap {
    justify-content: flex-end;
    margin: 30px 0;
  }
</style>

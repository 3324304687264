<template>
  <div class="following-component">
    <div
      v-if="loading"
      class="is-flex ion-align-items-center ion-justify-content-center pt-4"
    >
      <IonSpinner/>
    </div>

    <h3
      v-if="!loading && !followingData.length"
      class="ion-margin-top ion-text-center"
    > {{ $t('following.followPlaceholder') }} </h3>

    <company-podcasts
      v-for="(data, i) in followingData"
      :key="`data-${i}`"
      :company-details="data.company"
      :ref="company => {companies[i] = {company, id: data.company.id}}"
    >
      <SeriesPodcast
        v-for="course in data.courses"
        :key="course.id"
        :course-data="course"
        @click="$router.push(`/course/${course.id}`)"
      />
    </company-podcasts>
  </div>
</template>

<script>
import CompanyPodcasts from '@/components/common/CompanyPodcasts.vue';
import SeriesPodcast from '@/components/common/SeriesPodcast.vue';
import {
  IonSpinner,
} from '@ionic/vue';
import { computed, onBeforeUpdate, onMounted, ref, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  name: 'MainFollowing',
  components: {
    CompanyPodcasts,
    SeriesPodcast,
    IonSpinner,
  },

  props: {
    queryId: {
      type: [String, Boolean],
      default: null,
    },
  },



  setup(props) {
    const store = useStore();
    const loading = ref(false);

    const companies = ref([]);
    const followingData = computed(
      () => store.getters['favorites/getFollowingList'],
    );

    const fetchFollowing = () => {
      loading.value = true;
      store.dispatch('favorites/setFollowingList')
        .finally(() => {
          loading.value = false;
        });
    };

    const findCompany = (val) => {
      const find = companies.value.find(el => el.id === +val);
      if (find) {
        find.company.$el.scrollIntoView({ behavior: "smooth" });
      }
    };

    watch(
      () => props.queryId,
      (newValue) => {
        if (newValue) {
          findCompany(newValue);
        }
      },
    );

    watch(
      () => store.state.favorites.followersUpdated,
      (newValue) => {
        if (newValue) {
          fetchFollowing(newValue);
          store.commit('favorites/setFollowingUpdated', false);
        }
      },
    );

    onBeforeUpdate(() => {
      companies.value = [];
    });

    onMounted(() => {
      store.dispatch('favorites/setFollowingList').then(() => {
        if (props.queryId) {
          findCompany(props.queryId);
        }
      });
    });

    return {
      followingData,
      companies,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.sort-btn-wrap {
  justify-content: flex-end;
  margin: 30px 0;
}
</style>
